<template>
    <el-dialog title="设置编号规则" :before-close="handleClose" :visible.sync="seriaNumberFromVisible" @open="initSeriaNumberFrom">
        <el-form v-loading="loading" :model="seriaNumberFrom" :rules="rules2" status-icon ref="seriaNumberFrom"
            label-width="100px" class="seriaNumberFrom">
            <el-form-item label="编号规则">
                <el-checkbox :true-label="seriaNumberFrom.format.fixed == 2 ? seriaNumberFrom.format.fixed : 1"
                    :false-label="0" v-model="seriaNumberFrom.format.fixed" :disabled="seriaNumberFrom.format.fixed == 2"
                    @change="fixedChange">固定字符</el-checkbox>
                <el-checkbox :true-label="seriaNumberFrom.format.join_date == 2 ? seriaNumberFrom.format.join_date : 1"
                    :false-label="0" v-model="seriaNumberFrom.format.join_date"
                    :disabled="seriaNumberFrom.format.fixed == 2" @change="joinDateChange">入会时间</el-checkbox>
                <el-checkbox :true-label="seriaNumberFrom.format.sequence == 2 ? seriaNumberFrom.format.sequence : 1"
                    :false-label="0" v-model="seriaNumberFrom.format.sequence"
                    :disabled="seriaNumberFrom.format.sequence == 2">
                    自然序号</el-checkbox>
            </el-form-item>
            <div style="display:flex;">
                <el-form-item label="固定字符" prop="fixed">
                    <el-input v-model.trim="seriaNumberFrom.fixed_string" :disabled="seriaNumberFrom.format.fixed == 2"
                        placeholder="请输入字符" maxlength="10"></el-input>
                </el-form-item>
            </div>
            <el-form-item label="编号规则" prop="join_date">
                <el-checkbox label="年" :disabled="seriaNumberFrom.format.join_date == 2" @change="joinDateFormatChange"
                    :true-label="1" :false-label="0" v-model="seriaNumberFrom.join_date_format_year"></el-checkbox>

                <el-checkbox label="月" :disabled="seriaNumberFrom.format.join_date == 2" @change="joinDateFormatChange"
                    :true-label="1" :false-label="0" v-model="seriaNumberFrom.join_date_format_month"></el-checkbox>

                <el-checkbox label="日" :disabled="seriaNumberFrom.format.join_date == 2" @change="joinDateFormatChange"
                    :true-label="1" :false-label="0" v-model="seriaNumberFrom.join_date_format_day"></el-checkbox>
            </el-form-item>
            <div class="sequence-box">
                <p class="sequence-title">自然序号</p>
                <div>
                    <el-form-item label="序号位数为">
                        <el-select v-model="seriaNumberFrom.sequence_number" placeholder="请选择"
                            :disabled="seriaNumberFrom.format.sequence == 2">
                            <el-option v-for="item in 9" :key="item" :label="item + 2" :value="item + 2">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="起始序号为">
                        <el-input v-model.number.trim="seriaNumberFrom.sequence_start"
                            :disabled="seriaNumberFrom.format.sequence == 2" :maxlength="seriaNumberFrom.sequence_number"
                            :minlength="1" :placeholder="`请输入起始序号`"></el-input>
                    </el-form-item>
                </div>
            </div>
            <p class="example">示例: {{ example }}
            </p>
            <div class="reserve">

                <div style="display: flex;" class="reserve-input">
                    <div>设置预留序号</div><el-input type="textarea" :rows="4"
                        :placeholder="`请输入${seriaNumberFrom.sequence_number} 位数字序号，一行一个`"
                        v-model="getsequenceNumberReserved">
                    </el-input>
                </div>
                <div >
                    <span>
                        1、请输入 {{ seriaNumberFrom.sequence_number }} 位数字序号，一行一个
                    </span>
                    <span>
                        2、预留序号将不会被系统自动生成
                    </span>
                </div>
            </div>
            <el-form-item>
                <el-button type="primary" @click="submitSeriaNumberFrom('seriaNumberFrom')">确认</el-button>
                <el-button @click="resetSeriaNumberFrom('seriaNumberFrom')">取消</el-button>
            </el-form-item>
        </el-form>
    </el-dialog>
</template>

<script>
import { getCycMemberNumberConfig, setCycMemberNumberConfig,} from "../../api/cyc-other-config";
export default {
    props:['seriaNumberFromVisible'],
    data() {
        let join_date = (rule, value, callback) => {
            let { format, join_date_format_year, join_date_format_month, join_date_format_day } = this.seriaNumberFrom
            if (format.join_date == 1) {
                if ((join_date_format_year == 1 || join_date_format_month == 1 || join_date_format_day == 1)) {
                    callback()
                } else {
                    callback(new Error('请至少勾选一个入会时间选项!'))
                }
            } else {
                callback();
            }
        }
        let fixed = (rule, value, callback) => {
            let { format, fixed_string } = this.seriaNumberFrom
            if (format.fixed == 1) {

                if (fixed_string == '') {
                    callback(new Error('内容不能为空'))
                } else {
                    callback()
                }
            } else {
                callback()
            }
        }
        return {
            loading: false,
            // 设置编号规则
            seriaNumberFrom: {
                format: {
                    fixed: 0,
                    join_date: 0,
                    sequence: 0
                },
                fixed_string: "",
                join_date_format_year: 0,
                join_date_format_month: 0,
                join_date_format_day: 0,
                sequence_number: 0,
                sequence_start: 1,
                sequence_number_reserved: []
            },

            rules2: {
                join_date: [
                    { validator: join_date, trigger: "change" }
                ],
                fixed: [
                    { validator: fixed, trigger: "blur" }
                ]
            },
        }
    },
    computed: {
        // 会员编号示例补零
        getNumberLength() {
            if (this.seriaNumberFrom.sequence_number < this.seriaNumberFrom.sequence_start.toString().length) {
                return 0
            }
            return this.seriaNumberFrom.sequence_number - this.seriaNumberFrom.sequence_start.toString().length
        },
        //会员编号示例
        example() {
            let value = ''
            if (this.seriaNumberFrom.format.fixed != 0) {
                value += this.seriaNumberFrom.fixed_string
            }
            if (this.seriaNumberFrom.join_date_format_year) {
                value += new Date().getFullYear()  
            }
            if (this.seriaNumberFrom.join_date_format_month) {
                let m = new Date().getMonth() + 1
                if(m<10) {
                 m = "0" +""+ m;
                }
                value += m;
            }
            if (this.seriaNumberFrom.join_date_format_day) {
                value += new Date().getDate ()
            }
            if (this.seriaNumberFrom.format.sequence != 0) {
                for (let i = this.getNumberLength; i > 0; i--) {
                    value = value + '0'
                }
            }
            if (this.seriaNumberFrom.sequence != 0) {
                value += this.seriaNumberFrom.sequence_start
            }
            return value
        },
        getsequenceNumberReserved: {
            get() {
                return this.seriaNumberFrom.sequence_number_reserved.join('\n')
            },
            set(n) {
                this.seriaNumberFrom.sequence_number_reserved = n.split('\n')
            }
        },
    },
    methods:{
        resetSeriaNumberFrom(formName) {
            this.$emit('setSeriaNumberFromVisible')
      this.$refs[formName].resetFields();
    },
    handleClose(){
   
           this.$emit('setSeriaNumberFromVisible')
          
    },
    // 初始化会员编号表单
    async initSeriaNumberFrom() {
      this.loadinga = true
      let { data } = await getCycMemberNumberConfig()
      this.loadinga = false
      Object.keys(this.seriaNumberFrom).forEach((key) => {
        if (key == 'format') {
          Object.keys(this.seriaNumberFrom[key]).forEach((item) => {
            this.seriaNumberFrom[key][item] = data.format[item];
          })
        } else {
          this.seriaNumberFrom[key] = data[key];
        }
      })
    },
    fixedChange() {
      if (this.seriaNumberFrom.format.fixed == 0) {
        this.seriaNumberFrom.fixed_string = ''
      }
    },
    joinDateChange(value) {
      if (value == 0) {
        this.seriaNumberFrom.join_date_format_year = 0
        this.seriaNumberFrom.join_date_format_month = 0
        this.seriaNumberFrom.join_date_format_day = 0
      }
    },
    joinDateFormatChange(vlaue) {
      let { join_date_format_year, join_date_format_month, join_date_format_day } = this.seriaNumberFrom
      if (vlaue == 1 || join_date_format_year == 1 || join_date_format_month == 1 || join_date_format_day == 1) {
        this.seriaNumberFrom.format.join_date = 1
      } else {
        this.seriaNumberFrom.format.join_date = 0
      }
    },
    //保存编号规则
    submitSeriaNumberFrom(formName) {
      this.seriaNumberFrom.sequence_number_reserved = this.seriaNumberFrom.sequence_number_reserved.filter(v => v != '')
      console.log(this.seriaNumberFrom.sequence_number_reserved);
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          let config = { ...this.seriaNumberFrom }

          let data = await setCycMemberNumberConfig({ config })

          this.initSeriaNumberFrom()
          this.$message({
            message: data.msg,
            type: 'success'
          });

        } else {
          console.log('error ');
          return false;
        }
      });
    },
    }
}
</script>
<style lang="scss" scoped>
   

      .seriaNumberFrom {
        .sequence-box {
          display: flex;

          .sequence-title {
            font-size: 14px;
            color: #606266;
            height: 40px;
            line-height: 40px;
            width: 90px;
            text-align: right;

          }

          ::v-deep.el-form-item__label {
            text-align: center;


          }
        }
        .example {
          color: $primary;
          font-size: 14px;
          margin-left: 60px;
          height: 40px;

        }
      }
      .reserve {
      margin-bottom: 20px;

      .reserve-input {

        div:nth-child(1) {
          width: 110px;
        }
      }

      >div:nth-child(2) {
        color: $primary;
        font-size: 12px;
        display: flex;
        flex-direction: column;
        margin-left: 80px;
        margin-bottom: 10px;
      }
    }
    
</style>