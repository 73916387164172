<!--
 * @Author: mulingyuer
 * @Date: 2021-07-27 11:42:47
 * @LastEditTime: 2021-07-27 15:56:07
 * @LastEditors: mulingyuer
 * @Description: 选择证书弹窗
 * @FilePath: \saas-admin-vue\src\modules\organization-admin\components\cyc-other-config\CertDialog.vue
 * 怎么可能会有bug！！！
-->
<template>
  <el-dialog title="选择证书" :visible.sync="show" width="740px" class="dialog-vertical cert-dialog"
    @open="onOpen" @close="onClose">
    <div class="content" v-loading="loading">
      <div class="head">
        <el-form :model="form" :rules="rules" ref="ruleForm" label-width="0px" size="medium"
          class="medium-form">
          <div class="left">
            <el-form-item prop="type">
              <el-select v-model="form.type" placeholder="证书类型" @change="getCertList">
                <el-option v-for="op in typeArr" :key="op.id" :label="op.name" :value="op.id"></el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="right">
            <el-form-item prop="name">
              <el-input v-model="form.name" suffix-icon="el-icon-search" placeholder="请输入证书名称"
                @keydown.enter.native="getCertList"></el-input>
            </el-form-item>
          </div>
        </el-form>
      </div>
      <div class="body">
        <div class="cert-grid">
          <div class="cert-item" v-for="item in tbody" :key="item.id">
            <el-radio class="cert-radio" v-model="activeCert" :label="item.id"></el-radio>
            <div class="cert-item-top" @click="onPreview(item.cert_design.preview_image)">
              <img :src="fixImageUrl(item.cert_design.preview_image)">
            </div>
            <div class="cert-item-bottom">{{item.name}}</div>
          </div>
        </div>
        <Pagination :total="pageData.total" :page="pageData.current_page" :change="onChangPage" />
      </div>
    </div>
    <template #footer>
      <el-button size="small" @click="show=false">取消</el-button>
      <el-button v-if="!loading" size="small" type="primary" @click="onConfirm">确定</el-button>
    </template>
  </el-dialog>
</template>

<script>
import Pagination from "@/base/components/Default/Pagination";
import { certList } from "../../api/cyc-other-config";
export default {
  model: {
    prop: "open",
    event: "handleOpen",
  },
  props: {
    open: {
      type: Boolean,
      required: true,
    },
    complete: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      loading: false, //加载中
      form: {
        type: "", //证书类型
        tab: "", // 推送渠道
        name: "", //证书名称
      },
      rules: {},
      //证书类型选项数据
      typeArr: [
        { id: -1, name: "全部" },
        { id: 0, name: "系统模板" },
        { id: 1, name: "自定义模板" },
      ],
      //推送渠道数据
      tabArr: [
        { id: "all", name: "全部" },
        { id: "prepare_member", name: "预备会员" },
        { id: "formal_member", name: "正式会员" },
      ],
      postData: {},
      tbody: [],
      pageData: {},
      activeCert: "", //选择的证书id
    };
  },
  computed: {
    show: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("handleOpen", val);
      },
    },
  },
  methods: {
    //获取证书列表
    getCertList() {
      this.loading = true;
      const postData = { ...this.postData, ...this.form };
      certList(postData)
        .then((res) => {
          const { data } = res;
          this.pageData = data;
          this.tbody = data.data;

          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },

    //分页事件
    onChangPage(pageObj) {
      Object.assign(this.postData, pageObj);
      this.getCertList();
    },

    onOpen() {
      this.getCertList();
    },

    onClose() {
      this.tbody = [];
      this.pageData = {};
    },

    onConfirm() {
      if (this.activeCert) {
        const certData = this.tbody.find((item) => item.id === this.activeCert);
        certData && this.complete(certData);
      }

      this.show = false;
    },

    onPreview(...imgSrc) {
      this.$previewImage(imgSrc);
    },
  },
  components: {
    Pagination,
  },
};
</script>

<style lang="scss" scoped>
.cert-dialog {
  .content {
    .head {
      margin-bottom: 20px;
      .medium-form {
        display: flex;
        align-items: center;
        .left {
          min-width: 0;
          flex-grow: 1;
          .el-select {
            max-width: 160px;
          }
        }
        .right {
          flex-shrink: 0;
          width: 180px;
        }
        .el-form-item--medium {
          margin-bottom: 0;
        }
      }
    }
    .body {
      .cert-grid {
        display: grid;
        grid-template-columns: repeat(auto-fill, 160px);
        grid-template-rows: 170px;
        row-gap: 20px;
        column-gap: 20px;
      }
      .cert-item {
        // display: inline-block;
        // vertical-align: top;
        // width: 160px;
        // height: 170px;
        // margin-right: 20px;
        // margin-bottom: 20px;
        border: 1px solid #dcdfe6;
        position: relative;
        .cert-radio {
          position: absolute;
          top: 10px;
          right: 10px;
          margin-right: 0;
          ::v-deep.el-radio__label {
            display: none;
          }
          &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: -5px;
          }
        }
        .cert-item-top {
          height: 140px;
          overflow: hidden;
          padding: 5px;
          cursor: pointer;
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            vertical-align: top;
          }
        }
        .cert-item-bottom {
          text-align: center;
          font-size: 14px;
          height: 30px;
          line-height: 30px;
          color: #3a3a3a;
          @include no-select();
        }
      }
    }
  }
}
</style>