<!--
 * @Author: mulingyuer
 * @Date: 2021-07-27 10:35:20
 * @LastEditTime: 2021-07-30 10:06:44
 * @LastEditors: mulingyuer
 * @Description: 证书其他配置
 * @FilePath: \saas-admin-vue\src\modules\organization-admin\views\cyc-other-config\index.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="cyc-other-config">
    <div class="content" v-loading="loading">
      <!-- 会员身份 -->
      <div v-if="tenantVersion !== 'school'" class="config-item">
        <el-form
          :model="memberForm"
          ref="memberForm"
          :rules="rules"
          label-width="105px"
          label-position="left"
          size="medium"
          class="medium-form"
        >
          <h2 class="config-section-title">会员身份设置</h2>
          <el-form-item label="入会申请设置：">
            <div class="flex-col">
              <div>
                <el-checkbox
                  v-model="memberForm.personal_user_enable"
                  :true-label="1"
                  :false-label="0"
                  :disabled="
                    memberForm.personal_user_enable == 1 &&
                    memberForm.unit_user_enable != 1
                  "
                  >支持个人入会
                </el-checkbox>
                <el-button
                  size="small"
                  type="primary"
                  style="margin-left: 20px"
                  @click="handleCustomForm()"
                  >配置个人申请信息
                </el-button>
              </div>
              <div>
                <el-checkbox
                  v-model="memberForm.unit_user_enable"
                  :true-label="1"
                  :false-label="0"
                  :disabled="
                    memberForm.unit_user_enable == 1 &&
                    memberForm.personal_user_enable != 1
                  "
                  >支持单位入会
                </el-checkbox>
                <el-button
                  size="small"
                  type="primary"
                  style="margin-left: 20px"
                  @click="handleCustomForm('unit')"
                >
                  配置单位申请信息
                </el-button>
              </div>
            </div>
            <div
              class="info"
              style="padding-left: 20px; position: relative; margin-top: 20px"
            >
              <i
                class="el-icon-warning"
                style="
                  position: absolute;
                  left: 0;
                  line-height: 1.5;
                  font-size: 13px;
                "
              />
              <p class="info" style="line-height: 1.5; margin-top: 0">
                1.单位会员是指以公司的名义加入组织，个人会员是以个人身份加入组织。<br />
                2.当申请入会设置支持单位入会后，会员单位入会填写的表单即是企业申请信息的表单<br />
                3.当会员单位成功入会后，企业库亦会同时创建此单位信息<br />
                4.会员创建企业信息填写的表单是不需要填写会内职务信息的
              </p>
            </div>
          </el-form-item>
          <!-- 仅海外版生效的配置 -->
          <el-form-item
            v-if="true || [1, 2].includes($store.state.tenant.project_type)"
            class="fast-form"
            label="自动入会："
          >
            <el-radio-group v-model="memberForm.auto_join">
              <el-radio :label="1">开启</el-radio>
              <el-radio :label="0">关闭</el-radio>
            </el-radio-group>

            <div
              class="info"
              style="
                padding-left: 20px;
                position: relative;
                margin-bottom: 20px;
              "
            >
              <i
                class="el-icon-warning"
                style="
                  position: absolute;
                  left: 0;
                  line-height: 1.5;
                  font-size: 13px;
                "
              />
              <p class="info" style="line-height: 1.5; margin-top: 0">
                <!-- 开启自动入会，用户登录则自动加入架构职务，登录则需填写入会表单 -->
                开启时，当用户完成入会表单的所有必填项，便会自动入会
              </p>
            </div>
            <template v-if="memberForm.auto_join === 1">
              <el-form-item label="必填入会资料：" prop="force_join" style="margin-bottom: 0px;">
                <el-radio-group v-model="memberForm.force_join">
                  <el-radio :label="1">开启</el-radio>
                  <el-radio :label="0">关闭</el-radio>
                </el-radio-group>
                <div
                  class="info"
                  style="
                    padding-left: 20px;
                    position: relative;
                    margin-bottom: 20px;
                    transform: translateX(-105px);
                  "
                >
                  <i
                    class="el-icon-warning"
                    style="
                      position: absolute;
                      left: 0;
                      line-height: 1.5;
                      font-size: 13px;
                    "
                  />
                  <p class="info" style="line-height: 1.5; margin-top: 0">
                    登录后需要填写入会资料才可浏览/使用小程序
                  </p>
                </div>
              </el-form-item>
              <el-form-item style="margin-bottom: 0px" label="个人入会">
              </el-form-item>
              <el-form-item
                label="自动入会架构："
                prop="auto_join_organization_id"
              >
                <el-cascader
                  style="width: 80%"
                  v-model="memberForm.auto_join_organization_id"
                  :options="parentArr"
                  :props="{
                    value: 'id',
                    label: 'name',
                    checkStrictly: true,
                    expandTrigger: 'hover',
                  }"
                  placeholder="请选择组织架构"
                  @change="handleSelect($event, 'auto')"
                ></el-cascader>
              </el-form-item>
              <el-form-item label="自动入会职务：" prop="auto_join_position_id">
                <el-select
                  v-model="memberForm.auto_join_position_id"
                  :placeholder="
                    autoJoinPositionPlaceholder
                      ? autoJoinPositionPlaceholder
                      : '请先选择组织架构'
                  "
                  style="width: 80%"
                  :disabled="!memberForm.auto_join_organization_id"
                >
                  <el-option
                    v-for="item in autoPostList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
            </template>
          </el-form-item>
          <el-form-item v-if="false" class="fast-form" label="快捷入会：">
            <el-radio-group v-model="memberForm.quick_join">
              <el-radio :label="1">开启</el-radio>
              <el-radio :label="0">关闭</el-radio>
            </el-radio-group>
            <el-button
              size="small"
              type="primary"
              style="margin-left: 20px"
              @click="handleToDesign"
              >配置快捷入会信息</el-button
            >
            <div
              class="info"
              style="
                padding-left: 20px;
                position: relative;
                margin-bottom: 20px;
              "
            >
              <i
                class="el-icon-warning"
                style="
                  position: absolute;
                  left: 0;
                  line-height: 1.5;
                  font-size: 13px;
                "
              />
              <p class="info" style="line-height: 1.5; margin-top: 0">
                1.开启快捷入会无需填写入会申请信息、无需审批，缴费成功后即自动入会，后续可在【我的】页中完善个人信息
              </p>
              <p class="info" style="line-height: 1.5; margin-top: 0">
                2.开启快捷入会后，邀请好友将加入邀请者的对应组织
              </p>
            </div>
            <template
              v-if="
                memberForm.quick_join === 1 &&
                memberForm.personal_user_enable === 1
              "
            >
              <el-form-item style="margin-bottom: 0px" label="个人入会">
              </el-form-item>
              <el-form-item
                label="快捷入会架构："
                prop="personal_user_organization_id"
              >
                <el-cascader
                  style="width: 80%"
                  v-model="memberForm.personal_user_organization_id"
                  :options="parentArrOnly"
                  :props="{
                    value: 'id',
                    label: 'name',
                    checkStrictly: true,
                    expandTrigger: 'hover',
                  }"
                  placeholder="请选择组织架构"
                  @change="handleSelect($event, 'personal')"
                ></el-cascader>
              </el-form-item>
              <el-form-item
                label="快捷入会职务："
                prop="personal_user_position_id"
              >
                <el-select
                  v-model="memberForm.personal_user_position_id"
                  :placeholder="
                    personalPositionPlaceholder
                      ? personalPositionPlaceholder
                      : '请先选择组织架构'
                  "
                  style="width: 80%"
                  :disabled="!memberForm.personal_user_organization_id"
                >
                  <el-option
                    v-for="item in personalPostList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
            </template>
            <template
              v-if="
                memberForm.quick_join === 1 && memberForm.unit_user_enable === 1
              "
            >
              <el-form-item style="margin-bottom: 0px" label="单位入会">
              </el-form-item>
              <el-form-item
                label="快捷入会架构："
                prop="unit_user_organization_id"
              >
                <el-cascader
                  style="width: 80%"
                  v-model="memberForm.unit_user_organization_id"
                  :options="parentArrOnly"
                  :props="{
                    value: 'id',
                    label: 'name',
                    checkStrictly: true,
                    expandTrigger: 'hover',
                  }"
                  placeholder="请选择组织架构"
                  @change="handleSelect($event, 'unit')"
                ></el-cascader>
              </el-form-item>
              <el-form-item label="快捷入会职务：" prop="unit_user_position_id">
                <el-select
                  v-model="memberForm.unit_user_position_id"
                  :placeholder="
                    unitPositionPlaceholder
                      ? unitPositionPlaceholder
                      : '请先选择组织架构'
                  "
                  style="width: 80%"
                  :disabled="!memberForm.unit_user_organization_id"
                >
                  <el-option
                    v-for="item in unitPostList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
            </template>
          </el-form-item>
          <!-- <el-form-item  v-if="memberForm.quick_join === 1" label="固定职务入会：">
            <el-radio-group v-model="memberForm.select_position">
              <el-radio  :label="1">开启</el-radio>
              <el-radio  :label="0">关闭</el-radio>
            </el-radio-group>
          </el-form-item> -->
        </el-form>
      </div>

      <!-- 会员编号 -->
      <div class="seriaNumber">
        <h2 class="config-section-title">会员编号设置</h2>
        <el-button
          size="small"
          type="primary"
          @click="seriaNumberFromVisible = true"
        >
          设置编号规则
        </el-button>

        <el-button
          size="small"
          type="primary"
          style="margin-left: 20px"
          @click="ImportNumberLoading = true"
        >
          导入已有会员编号
        </el-button>
        <el-dialog
          title="批量导入已有会员编号"
          :width="'580px'"
          class="dialog-vertical"
          close-on-click-modal
          close-on-press-escape
          :visible.sync="ImportNumberLoading"
        >
          <div class="content-title">请按以下步骤操作：</div>
          <div class="content-step">
            步骤1：下载《批量导入已有会员编号模板》，按模板中的说明进行填写。
            <el-button
              class="btn"
              type="text"
              style="margin-left: 10px"
              @click="downloadExcel"
              >下载模板</el-button
            >
          </div>
          <div class="content-step">
            步骤2：上传文件后系统会自动进行批量导入。
          </div>
          <span slot="footer" class="dialog-footer footer-btn">
            <el-button size="small" @click="ImportNumberLoading = false"
              >取 消</el-button
            >
            <el-upload
              ref="excelExplorer"
              accept=".xlsx, .xls"
              action="#"
              :limit="1"
              :http-request="uploadExcel"
              :show-file-list="false"
              :disabled="uploadLoading"
            >
              <el-button
                size="small"
                type="primary"
                icon="el-icon-upload2"
                style="min-width: 100px"
                :loading="uploadLoading"
                >{{
                  uploadLoading
                    ? progress
                      ? `${progress}%`
                      : '导入中'
                    : '上传文件'
                }}</el-button
              >
            </el-upload>
          </span>
        </el-dialog>
      </div>
      <!-- 证书颁发 -->
      <div class="config-item">
        <el-form
          :model="form"
          :rules="rules"
          ref="ruleForm"
          label-width="0px"
          size="medium"
          class="medium-form"
        >
          <h2 class="config-section-title">证书颁发设置</h2>
          <el-form-item prop="prepare_cert_status" class="checkbox-item">
            <el-checkbox
              v-model="form.prepare_cert_status"
              :true-label="1"
              :false-label="0"
            >
              当用户成为 预备会员 时，系统将自动颁发证书
            </el-checkbox>
          </el-form-item>
          <el-form-item
            v-if="form.prepare_cert_status"
            prop="prepare_cert.id"
            key="prepare_cert"
          >
            <el-input v-show="false" v-model="form.prepare_cert.id"></el-input>
            <el-button @click="onOpenCertDialog('prepare')">选择证书</el-button>
            <span v-if="form.prepare_cert.name" class="button-info">
              已选择：<span class="text">{{ form.prepare_cert.name }}</span>
              <span class="delete-btn" @click="onDelete('prepare')">删除</span>
            </span>
          </el-form-item>
          <el-form-item prop="formal_cert_status" class="checkbox-item">
            <el-checkbox
              v-model="form.formal_cert_status"
              :true-label="1"
              :false-label="0"
            >
              当用户成为 正式会员 时，系统将自动颁发证书
            </el-checkbox>
          </el-form-item>
          <el-form-item
            v-if="form.formal_cert_status"
            prop="formal_cert.id"
            key="formal_cert"
          >
            <el-input v-show="false" v-model="form.formal_cert.id"></el-input>
            <el-button @click="onOpenCertDialog('formal')">选择证书</el-button>
            <span v-if="form.formal_cert.name" class="button-info">
              已选择：<span class="text">{{ form.formal_cert.name }}</span>
              <span class="delete-btn" @click="onDelete('formal')">删除</span>
            </span>
          </el-form-item>
        </el-form>
      </div>
      <div
        v-if="config !== null && tenantVersion !== 'school'"
        class="config-item"
      >
        <el-form
          label-width="70px"
          label-position="left"
          size="medium"
          class="medium-form"
        >
          <h2 class="config-section-title">组织架构展示配置</h2>
          <el-form-item label="架构展示">
            <el-radio-group v-model="config" class="flex-col">
              <el-radio :label="1">显示申请组织架构</el-radio>
              <el-radio :label="2">显示一级组织架构</el-radio>
              <el-radio :label="3">不显示组织架构</el-radio>
              <el-radio :label="0">不显示</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-form>
      </div>
      <div v-else-if="config !== null" class="config-item">
        <h2 class="config-section-title">标签设置</h2>
        <el-form
          class="small-form"
          size="small"
          label-width="110px"
          label-position="left"
        >
          <el-form-item label="标签显示方式">
            <el-radio-group v-model="config" class="flex-col">
              <el-radio
                v-for="op in displayOptions"
                :key="op.value"
                :label="op.value"
                >{{ op.label }}</el-radio
              >
            </el-radio-group>
          </el-form-item>
          <el-form-item
            v-if="config !== 0 && config !== 4"
            label="校友会显示方式"
          >
            <el-radio-group v-model="showConfig" class="flex-col">
              <el-radio
                v-for="op in schoolOptions"
                :key="op.value"
                :label="op.value"
                >{{ op.label }}</el-radio
              >
            </el-radio-group>
          </el-form-item>
        </el-form>
      </div>
      <div
        class="config-item"
        v-if="config !== null && tenantVersion === 'school'"
      >
        <div class="config-title">效果预览</div>
        <div class="config-content" style="position: relative">
          <img
            height="567px"
            :src="`${baseImgUrl}/organization-admin/title.png`"
          />
          <span style="position: absolute; left: 95px; bottom: 25px"
            >显示校友会名称 + 会内职务效果</span
          >
          <span style="position: absolute; left: 450px; bottom: 25px"
            >显示届别 + 学段 + 班级 + 校区名称</span
          >
        </div>
      </div>
      <!--      <div class="config-item" v-if="dockConfig">-->
      <!--        <h2 class="config-section-title">对接人配置</h2>-->
      <!--        <div class="config-content">-->
      <!--          <el-form class="small-form" size="small" label-width="70px" label-position="left">-->
      <!--            <el-form-item label="选择用户">-->
      <!--              <member-tag v-for="(t, i) in dockConfig" :key="t.user_id" @del="config.splice(i, 1)">-->
      <!--                {{t.nickname}}（{{t.title_name}}）-->
      <!--              </member-tag>-->
      <!--              <el-button class="member-btn" type="primary" @click="member.show = true">+ 添加</el-button>-->
      <!--              <div class="form-tip info">-->
      <!--                <i class="el-icon-warning"/>-->
      <!--                <span>选择的会员将被随机分配至预备会员的对接人</span>-->
      <!--              </div>-->
      <!--            </el-form-item>-->
      <!--          </el-form>-->
      <!--        </div>-->
      <!--      </div>-->
    </div>
    <fixed-action-bar v-if="!loading">
      <el-button
        type="primary"
        :loading="saveLoading"
        @click="onSave('ruleForm')"
        >保存</el-button
      >
    </fixed-action-bar>
    <!-- 会员编号修改弹窗 -->
    <seriaNumberDialog
      @setSeriaNumberFromVisible="setSeriaNumberFromVisible"
      :seriaNumberFromVisible="seriaNumberFromVisible"
    />
    <!-- 选择证书弹窗 -->
    <CertDialog v-model="openCertDialog" :complete="onActiveCert" />
    <!--    <select-member v-model="member.show" :members="dockConfig || []" @changeSelect="handleSelect"></select-member>-->
  </div>
</template>

<script>
// import {
//   getDockingConfig,
//   saveDockingConfig,
// } from "@/modules/organization-admin/api/other-config";
import {
  getOtherMemberConfig,
  saveOtherMemberConfig,
} from '@/modules/organization-admin/api/setting'
import {
  ParentList,
  PostOptions,
} from '@/modules/organization-admin/api/member/member-detail'
import {
  importMemberNumbersXlsx,
  importMemberNumbers,
  getIndexData,
} from '../../api/cyc-other-config'
import { set_cptable } from 'xlsx'
import * as cptable from 'xlsx/dist/cpexcel.full.mjs'
import {
  readXlsxFileData,
  runPromiseByQueue,
  sliceArrFn,
} from '@/base/utils/tool'
import FixedActionBar from '@/base/layout/FixedActionBar'
import CertDialog from '../../components/cyc-other-config/CertDialog.vue'
import seriaNumberDialog from '../../components/cyc-other-config/seriaNumberDialog.vue'
// import SelectMember from "@/modules/common/components/MemberSelector";
// import MemberTag from "@/modules/organization-admin/components/OtherConfig/MemberTag";

set_cptable(cptable)
export default {
  data() {
    return {
      baseImgUrl:'',
      loading: false, //加载中
      //是否显示会员编号表单
      seriaNumberFromVisible: false,
      //上传会员编号中
      uploadLoading: false,
      progress: 0,
      //是否显示编号导出
      ImportNumberLoading: false,
      loadinga: false,
      // 设置编号规则
      seriaNumberFrom: {
        format: {
          fixed: 0,
          join_date: 0,
          sequence: 0,
        },
        fixed_string: '',
        join_date_format_year: 0,
        join_date_format_month: 0,
        join_date_format_day: 0,
        sequence_number: 0,
        sequence_start: 1,
        sequence_number_reserved: [],
      },
      form: {
        prepare_cert_status: 0, //是否开启预备会员证书设置
        //证书配置
        prepare_cert: {
          id: '',
          name: '',
        },
        formal_cert_status: 0, //是否开启正式会员证书设置
        formal_cert: {
          id: '',
          name: '',
        },
      },
      memberForm: {
        personal_user_enable: 1,
        unit_user_enable: 1,
        select_position: 0,
        quick_join: 1,
        personal_user_organization_id: '', // 个人快捷入会组织混淆 ID
        personal_user_position_id: '', // 个人快捷入会职务混淆 ID
        unit_user_organization_id: '', // 单位快捷入会组织混淆 ID
        unit_user_position_id: '', // 单位快捷入会职务混淆 ID

        auto_join: 0,
        auto_join_position_id: '',
        auto_join_organization_id: '',
      },
      parentArr: [],
      parentArrOnly: [],
      unitPostList: [],
      personalPostList: [],
      autoPostList: [],
      unitPositionPlaceholder: '',
      personalPositionPlaceholder: '',
      autoJoinPositionPlaceholder: '',
      rules: {
        'prepare_cert.id': [
          { required: true, message: '请选择证书', trigger: 'change' },
        ],
        'formal_cert.id': [
          { required: true, message: '请选择证书', trigger: 'change' },
        ],
        personal_user_organization_id: [
          { required: true, message: '请选择架构', trigger: 'change' },
        ],
        personal_user_position_id: [
          { required: true, message: '请选择职务', trigger: 'change' },
        ],
        unit_user_organization_id: [
          { required: true, message: '请选择架构', trigger: 'change' },
        ],
        unit_user_position_id: [
          { required: true, message: '请选择职务', trigger: 'change' },
        ],
        auto_join_position_id: [
          { required: true, message: '请选择职务', trigger: 'change' },
        ],
        auto_join_organization_id: [
          { required: true, message: '请选择架构', trigger: 'change' },
        ]
      },
      saveLoading: false, //保存中
      openCertDialog: false, //是否显示证书弹窗
      activeCert: '', //选择哪个证书：prepare、formal
      options: [
        { value: 99, label: '显示校友会名称 + 会内职务' },
        { value: 4, label: '显示届别 + 学段 + 班级 + 校区名称' },
        { value: 0, label: '不显示' },
        { value: 1, label: '显示申请的校友会名称' },
        { value: 2, label: '显示最高层级的校友会名称' },
        { value: 3, label: '不显示校友会名称，仅显示会内职务' },
      ],
      config: null,
      showConfig: null,
      paramsData: null,
      // dockConfig: null,
      // member: {
      //   show: false,
      // },
    }
  },
  computed: {
    tenantVersion() {
      return this.$store.getters.tenant?.version
    },
    // 0、4、99
    displayOptions() {
      return this.options.filter((e) => e.value < 0 || e.value > 3)
    },
    // 1、2、3
    schoolOptions() {
      return this.options.filter((e) => e.value > 0 && e.value < 4)
    },
  },
  methods: {
    // 跳转快捷入会
    handleToDesign() {
      // let paramsData = this.$store.getters["formPage/paramsData"];
      let routerData = this.$router.resolve({
        name: 'AppDesignPage',
        params: {
          platform: this.paramsData.platform,
          id: this.paramsData.id,
        },
        query: {
          system_page: 'quick_join',
        },
      })
      window.open(routerData.href, '_blank')
    },
    // 关闭会员编号修改弹窗
    setSeriaNumberFromVisible() {
      this.seriaNumberFromVisible = false
    },
    // 获取组织架构
    getOrganizationsTree(only_have_position = 1) {
      ParentList({
        is_all: 1,
        is_show_none: 0,
        only_have_position,
        hashid_enable: 1,
      }).then((res) => {
        if (only_have_position) {
          this.parentArrOnly = res.data
        } else {
          this.parentArr = res.data
        }
      })
    },
    // 选择组织架构操作
    handleSelect(e, type) {
      if (type === 'personal') {
        this.memberForm.personal_user_position_id = ''
      } else if (type === 'auto') {
        // 自动入会
        this.memberForm.auto_join_position_id = ''
      } else {
        this.memberForm.unit_user_position_id = ''
      }
      this.getPositions({ id: e[e.length - 1], type })
    },
    // 获取该架构下的职务
    getPositions({ id, type }) {
      if (type === 'personal') {
        this.personalPositionPlaceholder = '职务加载中...'
      } else if (type === 'auto') {
        this.autoJoinPositionPlaceholder = '职务加载中...'
      } else {
        this.unitPositionPlaceholder = '职务加载中...'
      }
      PostOptions({
        organization_id: id,
        is_show_prepare: 1,
        hashid_enable: 1,
      }).then((res) => {
        if (type === 'personal') {
          this.personalPostList = res.data
          this.personalPositionPlaceholder = '请选择入会职务'
        } else if (type === 'auto') {
          this.autoPostList = res.data
          this.autoJoinPositionPlaceholder = '请选择入会职务'
        } else {
          this.unitPostList = res.data
          this.unitPositionPlaceholder = '请选择入会职务'
        }
      })
    },
    // handleSelect(e) {
    //   this.dockConfig = e.map((item) => {
    //     return {
    //       nickname: item.nickname,
    //       title_name: item.title_name,
    //       user_id: item.user_id,
    //     };
    //   });
    // },
    /* 对接人配置 */
    // getDockConfig() {
    //   getDockingConfig()
    //     .then((res) => {
    //       this.dockConfig = res.data || [];
    //     })
    //     .catch(() => {
    //     });
    // },
    /* 配置 title_name 格式 */
    handleTitleNameShowConfig(config = '0') {
      config = Number(config)
      if (
        this.tenantVersion === 'school' &&
        this.displayOptions.filter((el) => el.value === config).length
      ) {
        this.showConfig = config
        this.config = 99
      } else if (this.tenantVersion === 'school') {
        this.showConfig = config
        this.config = 99
      } else {
        this.config = config
      }
    },
    // 自定义申请跳转
    handleCustomForm(pageset = 'user') {
      let routerData = this.$router.resolve({
        name: pageset === 'user' ? 'FormPage' : 'CycCompanyFormEditor',
        params: { id: 0, pageset: pageset },
      })
      window.open(routerData.href, '_blank')
    },
    // 获取多个配置
    getMemberConfig() {
      this.loading = true
      getOtherMemberConfig()
        .then((res) => {
          this.memberForm = res.data.cyc_apply_role

          if (res.data.cyc_apply_role.unit_user_organization_id) {
            let id = res.data.cyc_apply_role.unit_user_organization_id
            this.getPositions({ id, type: 'unit' })
            res.data.cyc_apply_role.unit_user_organization_id = [id]
          }
          if (res.data.cyc_apply_role.personal_user_organization_id) {
            let id = res.data.cyc_apply_role.personal_user_organization_id

            this.getPositions({ id, type: 'personal' })
            res.data.cyc_apply_role.personal_user_organization_id = [id]
          } else {
          }
          if (res.data.cyc_apply_role.auto_join_organization_id) {
            let id = res.data.cyc_apply_role.auto_join_organization_id

            this.getPositions({ id, type: 'auto' })
            res.data.cyc_apply_role.auto_join_organization_id = [id]
          }
          this.memberForm = res.data.cyc_apply_role
          this.handleTitleNameShowConfig(res.data.cyc_organization_show)
          this.initForm(res.data.member_cert_config)
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    // 下载模版
    downloadExcel() {
      importMemberNumbersXlsx()
    },
    // 导入会员编号
    uploadExcel(e) {
      this.uploadLoading = true
      this.progress = 0
      readXlsxFileData(
        e.file,
        (data, titles, list) => {
          this.$refs.excelExplorer.clearFiles()
          const size = 100
          const sliceArr = sliceArrFn(list, size)

          const errMsgArr = []
          let count = 0

          const queue = runPromiseByQueue(
            sliceArr.map((s, sI) => {
              return () => {
                return new Promise((resolve) => {
                  importMemberNumbers({
                    titles,
                    list: s,
                    // excel 里面 行号 3 才是真实数据开始
                    start_row: sI * size + 3,
                  })
                    .then((res) => {
                      if (!res.data.status) {
                        /* 记录不同的导入失败原因 */
                        if (
                          !errMsgArr.find((errText) => errText === res.data.msg)
                        ) {
                          errMsgArr.push(res.data.msg)
                        }
                      }
                      count++
                      if (count === sliceArr.length) {
                        this.progress = 100
                      } else {
                        this.progress = Math.floor(
                          (count * 100) / sliceArr.length
                        )
                      }
                      resolve()
                    })
                    .catch(() => {
                      resolve()
                    })
                })
              }
            })
          )

          Promise.all([queue])
            .then(() => {
              if (errMsgArr.length) {
                this.$msgbox
                  .confirm(errMsgArr.join('；<br/>'), '导入失败', {
                    type: 'error',
                    showCancelButton: false,
                    dangerouslyUseHTMLString: true,
                  })
                  .then(() => {})
                  .catch(() => {})
              } else {
                this.$message.success('导入成功')
              }

              this.uploadLoading = false
              this.update()
            })
            .catch(() => {})
        },
        () => {
          this.uploadLoading = false
          this.$refs.excelExplorer.clearFiles()
        }
      )
    },
    // 初始化证书配置表单
    initForm(data) {
      Object.keys(this.form).forEach((key) => {
        this.form[key] = data[key]
      })
    },
    // 保存
    onSave(formName) {
      let is
      this.$refs.memberForm.validate((valid) => {
        if (valid) {
          is = true
        } else {
          is = false
        }
      })
      if (!is) return
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.saveLoading = true
          const tasks = []
          let myMemberForm = JSON.parse(JSON.stringify(this.memberForm))
          myMemberForm.personal_user_organization_id =
            this.memberForm.personal_user_organization_id[0]
          myMemberForm.unit_user_organization_id =
            this.memberForm.unit_user_organization_id[0]
          myMemberForm.auto_join_organization_id =
            this.memberForm.auto_join_organization_id[0]

          tasks.push(
            saveOtherMemberConfig({
              config: {
                cyc_apply_role: myMemberForm,
                member_cert_config: this.form || [],
                cyc_organization_show:
                  this.config === 99
                    ? this.showConfig
                    : this.config === 0
                    ? '0'
                    : this.config,
              },
            })
          )
          // tasks.push(saveDockingConfig({
          //   config: this.dockConfig,
          // }))
          console.log(tasks)
          Promise.all(tasks)
            .then(() => {
              this.$message.success('保存成功')
              this.saveLoading = false
            })
            .catch(() => {
              this.saveLoading = false
            })
        } else {
          console.log('not valid')
        }
      })
    },
    // 打开证书弹窗
    onOpenCertDialog(name) {
      this.activeCert = name
      this.openCertDialog = true
    },
    // 选中证书
    onActiveCert({ id, name }) {
      let tagger = null
      switch (this.activeCert) {
        case 'prepare':
          tagger = this.form.prepare_cert
          break
        case 'formal':
          tagger = this.form.formal_cert
          break
      }
      Object.assign(tagger, { id, name })
    },
    // 删除证书
    onDelete(name) {
      let tagger = null
      switch (name) {
        case 'prepare':
          tagger = this.form.prepare_cert
          break
        case 'formal':
          tagger = this.form.formal_cert
          break
      }
      Object.assign(tagger, { id: '', name: '' })
    },
  },
  created() {
    this.getMemberConfig()
    this.getOrganizationsTree(0)
    this.getOrganizationsTree()
    getIndexData()
      .then((res) => {
        this.paramsData = res.data.find((el) => el.platform === 'mp-weixin')
      })
      .catch((err) => {})
    // this.getDockConfig()
    this.baseImgUrl = window.serverConfig.VUE_APP_IMG_URL
  },
  components: {
    // MemberTag,
    // SelectMember,
    FixedActionBar,
    CertDialog,
    seriaNumberDialog,
  },
}
</script>

<style lang="scss" scoped>
.cyc-other-config {
  .content {
    .medium-form {
      .checkbox-item {
        margin-bottom: 10px;

        & + .checkbox-item {
          margin-top: 32px;
        }
      }

      .button-info {
        margin-left: 20px;

        .text {
          color: #666;
        }

        .delete-btn {
          margin-left: 10px;
          color: $danger;
          cursor: pointer;
          @include no-select();

          &:hover {
            opacity: 0.7;
          }

          &:active {
            opacity: 0.6;
          }
        }
      }
    }

    .seriaNumber {
      margin-bottom: 20px;
    }
  }
}

.config-item + .config-item {
  margin-top: 70px;
}

.flex-col {
  .el-radio {
    line-height: 32px;
  }
}

.member-tag,
.member-btn {
  margin-right: 12px;
  margin-bottom: 12px;
}
.fast-form {
  ::v-deep > .el-form-item__label {
    text-align: right;
  }
}
.content-title {
  line-height: 1;
  color: #3a3a3a;
}
.content-step {
  display: block;
  margin-top: $space;
  display: flex;
  .btn {
    padding: 0;
    margin-right: 10px;
  }
}
.footer-btn {
  display: flex;
  float: right;
  padding-bottom: 20px;
  .el-button {
    margin-left: 20px;
  }
}
</style>
