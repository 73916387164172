import { api } from "@/base/utils/request";


export const getOtherMemberConfig = () => {
  return api({
    url: '/admin/cyc/settings/getCycMemberConfig',
    method: 'post'
  })
}

export const saveOtherMemberConfig = (data) => {
  return api({
    url: '/admin/cyc/settings/setCycMemberConfig',
    method: 'post',
    data
  })
}

 